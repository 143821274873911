import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Navigation from "./Common/Navigations/Navigation";
import About from "./Components/Pages/About/About";
import Home from "./Components/Pages/Home/Home";
import Footer from "./Common/Footer";
import SubCategoryslug from "./Components/Pages/Slug/SubCategoryslug";
import CategorySlug from "./Components/Pages/Slug/CategorySlug";
import IdSlug from "./Components/Pages/Slug/IdSlug";
import NoticeIdSlug from "./Components/Pages/Slug/NoticeIdSlug";
import Subsubcategory from "./Components/Pages/Slug/Subsubcategory";
import PageNotFound from "./Common/PageNotFound";
import Contact from "./Components/Pages/Contact/Contact";
import MessageFromPri from "./Components/Pages/About/MessageFromPri";
import Team from "./Components/Pages/About/team/Team";
import Academic from "./Components/Pages/Academic/Academic";
import Gallery from "./Components/Pages/Gallery/Gallery";
import Videos from "./Components/Pages/Gallery/Videos";
import AllNewsEvent from "./Components/Pages/Home/AllNewsEvent";
import AllNotice from "./Components/Pages/Home/AllNotice";
import Notes from "./Components/Pages/Notes/Notes";

const UIndex = () => {
  const Location = useLocation().pathname;

  return (
    <>
      {Location.includes("/admin") ? (
        <></>
      ) : (
        <>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="index.html" element={<Navigate to="/" />} />
            <Route path="/about-us/introduction" element={<About />} />
            <Route
              path="/about-us/principal-message"
              element={<MessageFromPri />}
            />
            <Route path="/about-us/citizen-team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/academics/:faculty" element={<Academic />} />
            <Route path="/gallery/photo-gallery" element={<Gallery />} />
            <Route path="/gallery/video-gallery" element={<Videos />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/news&event" element={<AllNewsEvent />} />
            <Route
              path="/extensions/news-and-events"
              element={<AllNewsEvent />}
            />
            <Route path="/extensions/notice" element={<AllNotice />} />
            <Route path="/notice" element={<AllNotice />} />
            <Route path="/blogs/filter/:id" element={<IdSlug />} />
            <Route path="/home/:id" element={<IdSlug />} />
            <Route path="/news&events/:id" element={<IdSlug />} />
            <Route path="/notice/:id" element={<NoticeIdSlug />} />
            <Route path="/:category/" element={<CategorySlug />} />
            <Route
              path="/:category/:subcategory/"
              element={<SubCategoryslug />}
            />
            <Route
              path="/:category/:subcategory/:subsubcategory"
              element={<Subsubcategory />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

export default UIndex;
