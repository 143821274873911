import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import VideosContext from "../../../context/videos/VideosContext";
import SubVideosModal from "../Modals/SubVideosModal";
import VideosModal from "../Modals/VideosModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Videos = () => {
  const {
    videosData,
    getvideos,
    deletevideos,
    render,
    getSubvideos,
    subvideosrender,
    uploadProgress,
  } = useContext(VideosContext);
  const [AddBtnCk, setAddBtnClk] = useState(false);
  const [btndata, setBtnData] = useState({ name: "", id: "" });
  const [subvideosmodalOpen, setSubvideosModalOpen] = useState(false);
  const [FilteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);

  // const getSubvideos =
  const editBtnClk = (id) => {
    setAddBtnClk(true);
    setBtnData({ name: "update", id });
    const data = videosData.filter((e) => e.id === id);
    setEditData(data);
  };
  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };

  //for sub videos modal open
  const subvideosClk = (id) => {
    const data = videosData.filter((e) => e.id === id);
    setFilteredData(data);
    setSubvideosModalOpen(true);
  };
  useEffect(() => {
    getvideos();
    getSubvideos();
    // eslint-disable-next-line
  }, [render, subvideosrender]);

  const SubCategoryAddBtnCLk = () => {
    setAddBtnClk(true);
    setBtnData({ name: "addBtn", id: -1 });
  };
  const paginationValue = 5;
  const [pagValue, setPagValue] = useState(1);
  const totalData = videosData.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = videosData.filter((e) =>
    e.title_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );
  return (
    <>
      <div className="h-[80vh] overflow-y-auto mt-12 md:ml-[255px]">
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Videos
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1 w-[10%]">S.N</th>
                <th className="border p-1 w-[45%]">Title</th>
                <th className="border p-1 w-[45%]">Video</th>
                {/* <th className="border p-1 w-[30%]">Sub-Videos</th> */}
                <th className="border p-1 !w-[100px]">Action</th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {videosData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[25%]">
                            {e.title_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            <video className="h-[30px] w-[30px] rounded-full m-auto object-cover">
                              <source src={e.gallery_video} type="video/mp4" />
                            </video>
                          </td>
                          {/* <td className="border px-1 min-w-[100px] w-[15%] relative">
                            {e.video_gallery_id.length > 0 ? (
                              <>
                                <div
                                  className="absolute top-0 right-0 bottom-0 left-0 m-auto duration-500 bg-[#2b1b1b85] hover:bg-[#2b1b1bc9] cursor-pointer flex items-center justify-center z-20 h-[30px] w-[30px] rounded-full"
                                  onClick={() => subvideosClk(e.id)}
                                >
                                  <p className="text-white text-sm tracking-wide">
                                    {e.video_gallery_id.length}+
                                  </p>
                                </div>
                                <video className="h-[30px] w-[30px] rounded-full m-auto p-2 object-cover z-10">
                                  <source
                                    src={e.gallery_video}
                                    type="video/mp4"
                                  />
                                </video>
                              </>
                            ) : (
                              "-"
                            )}
                          </td> */}
                          <td className="px-1 !w-[130px] h-[40px] relative flex items-center justify-center">
                            <button
                              className="edit actionIcons top-11 left-5"
                              onClick={() => editBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons top-11 right-5">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[25%]">
                            {e.title_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            <video
                              controls
                              className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                            >
                              <source src={e.gallery_video} type="video/mp4" />
                            </video>
                          </td>
                          <td className="border px-1 min-w-[100px] w-[15%] relative">
                            {e.video_gallery_id.length > 0 ? (
                              <>
                                <div
                                  className="absolute top-0 right-0 bottom-0 left-0 m-auto duration-500 bg-[#2b1b1b85] hover:bg-[#2b1b1bc9] cursor-pointer flex items-center justify-center z-20 h-[30px] w-[30px] rounded-full"
                                  onClick={() => subvideosClk(e.id)}
                                >
                                  <p className="text-white text-sm tracking-wide">
                                    {e.video_gallery_id.length}+
                                  </p>
                                </div>
                                <video className="h-[30px] w-[30px] rounded-full m-auto p-2 object-cover z-10">
                                  <source
                                    src={e.gallery_video}
                                    type="video/mp4"
                                  />
                                </video>
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="px-1 min-w-[100px] w-[8%] h-[40px] relative flex items-center justify-center">
                            <button
                              className="edit actionIcons top-11 left-5"
                              onClick={() => editBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons top-11 right-5">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btndata.id}
              deleteFun={deletevideos}
              // deleteMsg={deleteMsg}
            />
          ) : (
            <></>
          )}
          {videosData.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {AddBtnCk ? (
        <VideosModal
          AddBtnCk={AddBtnCk}
          setAddBtnClk={setAddBtnClk}
          btndata={btndata}
          editData={editData}
          uploaded={uploadProgress}
        />
      ) : (
        ""
      )}
      {subvideosmodalOpen ? (
        <SubVideosModal
          setModalOpen={setSubvideosModalOpen}
          FilteredData={FilteredData}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Videos;
