import React from "react";
import { Link } from "react-router-dom";

const EachNewsCards = (props) => {
  const { elm } = props;
  return (
    <>
      <div
        className="min-w-[240] max-w-[300px] h-[275px] relative rounded-sm"
        key={elm.id}
      >
        <img
          src={elm.image}
          alt="news "
          className="w-full h-[200px] object-cover shadow-lg"
          loading="lazy"
        />
        <div className="font-semibold tracking-wide mt-1 text-[#515151]">
          {React.createElement("span", {
            dangerouslySetInnerHTML: {
              __html:
                elm.blog_title.length > 50
                  ? elm.blog_title.slice(0, 50) + "..."
                  : elm.blog_title,
            },
          })}
        </div>
        <div className="flex justify-end px-2 absolute bottom-2 right-0">
          <Link
            to={`/news&events/${elm.id}`}
            className="underline text-[#041962] -mt-2 text-xs font-semibold"
          >
            Read More
          </Link>
        </div>
      </div>
    </>
  );
};

export default EachNewsCards;
