import React from 'react'

const Cards = () => {
  return (
    <div className='h-full overflow-y-auto ml-0 md:ml-[255px]'>
      this is cards
    </div>
  )
}

export default Cards
