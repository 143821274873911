export const closeBtn = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57932 25.7893C3.14667 24.4056 2.00394 22.7505 1.2178 20.9204C0.431667 19.0903 0.0178736 17.122 0.000566351 15.1303C-0.0167409 13.1387 0.362785 11.1635 1.117 9.32001C1.87121 7.47657 2.98501 5.80179 4.3934 4.3934C5.80179 2.98501 7.47657 1.87121 9.32001 1.117C11.1635 0.362785 13.1387 -0.0167409 15.1303 0.000566351C17.122 0.0178736 19.0903 0.431667 20.9204 1.2178C22.7505 2.00394 24.4056 3.14667 25.7893 4.57932C28.5217 7.40836 30.0336 11.1974 29.9994 15.1303C29.9653 19.0633 28.3877 22.8255 25.6066 25.6066C22.8255 28.3877 19.0633 29.9653 15.1303 29.9994C11.1974 30.0336 7.40836 28.5217 4.57932 25.7893ZM17.2843 15.1843L21.5293 10.9393L19.4143 8.82432L15.1843 13.0693L10.9393 8.82432L8.82432 10.9393L13.0693 15.1843L8.82432 19.4293L10.9393 21.5443L15.1843 17.2993L19.4293 21.5443L21.5443 19.4293L17.2993 15.1843H17.2843Z"
      fill="#147979"
    />
  </svg>
);
