import React, { useContext, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import CategoryContext from "../../../context/category/CategoryContext";
import SubsubCategoryModal from "../Modals/SubsubCategoryModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const SubSubCategory = () => {
  const {
    getSubSubCategories,
    subSubCategory,
    deleteSubSubCategory,
    getSubCategories,
    SubCategoryData,
    rendersubSubCategoryData,
  } = useContext(CategoryContext);
  const [btnclktype, setbtnclktype] = useState({ name: "", id: "" });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getSubSubCategories(); // eslint-disable-next-line
  }, [rendersubSubCategoryData]);

  useEffect(() => {
    getSubCategories(); // eslint-disable-next-line
  }, [rendersubSubCategoryData]);

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setbtnclktype({ id });
  };

  const SubCategoryAddBtnCLk = () => {
    setbtnclktype({ name: "Addbtn", id: -1 });
    setModalOpen(true);
  };
  const updateBtnClk = (id) => {
    setbtnclktype({ name: "update", id });
    setModalOpen(true);
  };
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = subSubCategory.length;
  const totalPage = Math.ceil(totalData / paginationValue);
  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = subSubCategory.filter((e) =>
    e.sub_category_key_name
      .toLowerCase()
      .includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div
        className="h-full overflow-y-auto ml-0 md:ml-[265px] mt-10"
        id="category"
      >
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Sub-Sub-Category
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Category Name
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Subcategory Name
                </th>
                <th className="border p-1" style={{ width: "200px" }}>
                  Subsubcategory Name
                </th>
                {/* <th className="border p-1" style={{ width: "310px" }}>
                  Active
                </th> */}
                <th className="border p-1" style={{ width: "100px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {subSubCategory
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[100px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.category_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.sub_category_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            {e.sub_category_key_name}
                          </td>
                          <td className="flex items-center justify-center p-1 h-[40px]">
                            <button
                              className="edit actionIcons "
                              onClick={() => updateBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[100px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.category_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.sub_category_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            {e.sub_category_key_name}
                          </td>
                          <td className="flex items-center justify-center p-1 h-[40px]">
                            <button
                              className="edit actionIcons "
                              onClick={() => updateBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnclktype.id}
              deleteFun={deleteSubSubCategory}
            />
          ) : (
            <></>
          )}
          {subSubCategory.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {modalOpen ? (
          <SubsubCategoryModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            SubCategoryData={SubCategoryData}
            btnclktype={btnclktype}
            subSubCategory={subSubCategory}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SubSubCategory;
