import React, { useEffect, useState } from "react";
import BannerModal from "../Modals/BannerModal";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { useContext } from "react";
import BannerContext from "../../../context/banner/BannerContext";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Banner = () => {
  const [AddBtnCk, setAddBtnClk] = useState(false);
  const { getFooter, data, render, deleteBanner, uploadProgress } =
    useContext(BannerContext);
  const [btnData, setBtnData] = useState({ name: "", id: "" });
  const [editableData, setEditableData] = useState([]);

  useEffect(() => {
    getFooter();
    // eslint-disable-next-line
  }, [render]);

  const editbtnclk = (name, id) => {
    setBtnData({ name: name, id: id });
    setAddBtnClk(true);
    const filterdata = data.filter((e) => e.id === id);
    setEditableData(filterdata);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };

  //add Button function
  const AddBtnClick = (name, id) => {
    setBtnData({ name: name, id: id });
    setAddBtnClk(true);
  };

  const paginationValue = 6;
  const [pagValue, setPagValue] = useState(1);
  const totalData = data.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = data.filter((e) =>
    e.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <div className="h-full overflow-y-auto mt-3 md:ml-[270px] relative">
      <button
        onClick={() => AddBtnClick("addBtn", -1)}
        className=" addbutton absolute top-5 right-3 rounded-[5px] border px-3 py-2 flex items-center justify-center"
      >
        Add Banner <BsPlusLg className="mt-0 ml-1 text-[11px]" />
      </button>
      <table
        border="1"
        className="border  mt-[70px] border-b-0"
        style={{ borderCollapse: "collapse", width: "99%" }}
      >
        <thead>
          <tr className="border text-center" style={{ height: "40px" }}>
            <th className="border text-sm p-1" style={{ width: "100px" }}>
              S.N
            </th>
            <th className="border text-sm p-1" style={{ width: "320px" }}>
              Name
            </th>
            <th className="border text-sm p-1" style={{ width: "310px" }}>
              Title
            </th>
            <th className="border text-sm p-1" style={{ width: "300px" }}>
              Slogan
            </th>
            <th className="border text-sm p-1" style={{ width: "300px" }}>
              Banner Image
            </th>
            <th className="border text-sm p-1" style={{ width: "10px" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {searchValue.length < 2 ? (
            <>
              {
                // eslint-disable-next-line
                data
                  .slice(
                    (pagValue - 1) * paginationValue,
                    pagValue * paginationValue
                  )
                  .map((e, index) => {
                    return (
                      <tr
                        style={{ height: "40px" }}
                        key={e.id}
                        className="border text-center border-b-0"
                      >
                        <td className="border p-1 text-sm w-[5%]">
                          {index + 1}
                        </td>
                        <td className="border p-1 text-sm w-[25%]">
                          {e.name.slice(0, 40)}
                        </td>
                        <td className="border p-1 text-sm w-[25%]">
                          {e.title.slice(0, 40)}
                        </td>
                        <td className="border p-1 text-sm w-[20%]">
                          {e.slogan}
                        </td>
                        <td className="border p-1 text-sm w-[15%]">
                          <img
                            src={e.banner_image}
                            className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                            alt=""
                            loading="lazy"
                          />{" "}
                        </td>
                        <td className="flex items-center justify-center mt-1 h-[40px]">
                          <button className="edit actionIcons ">
                            <MdEdit
                              className="text-xl text-white icons"
                              onClick={() => editbtnclk("updateBtn", e.id)}
                            />
                          </button>
                          <button className="delete actionIcons ">
                            <MdDelete
                              className="text-xl text-white icons"
                              onClick={() => deleteBtnClk(e.id)}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
              }
            </>
          ) : (
            <>
              {
                // eslint-disable-next-line
                searchData
                  .slice(
                    (pagValue - 1) * paginationValue,
                    pagValue * paginationValue
                  )
                  .map((e, index) => {
                    return (
                      <tr
                        style={{ height: "40px" }}
                        key={e.id}
                        className="border text-center border-b-0"
                      >
                        <td className="border p-1 text-sm w-[5%]">
                          {index + 1}
                        </td>
                        <td className="border p-1 text-sm w-[25%]">
                          {e.name.slice(0, 40)}
                        </td>
                        <td className="border p-1 text-sm w-[25%]">
                          {e.title.slice(0, 40)}
                        </td>
                        <td className="border p-1 text-sm w-[20%]">
                          {e.slogan.length > 20
                            ? e.slogan.slice(0, 20) + "..."
                            : e.slogan}
                        </td>
                        <td className="border p-1 text-sm w-[15%]">
                          <img
                            src={e.banner_image}
                            className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                            alt=""
                            loading="lazy"
                          />{" "}
                        </td>
                        <td className="flex items-center justify-center mt-1 h-[40px]">
                          <button className="edit actionIcons ">
                            <MdEdit
                              className="text-xl text-white icons"
                              onClick={() => editbtnclk("updateBtn", e.id)}
                            />
                          </button>
                          <button className="delete actionIcons ">
                            <MdDelete
                              className="text-xl text-white icons"
                              onClick={() => deleteBtnClk(e.id)}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
              }
            </>
          )}
        </tbody>
      </table>
      {open ? (
        <DeleteAlert
          open={open}
          setOpen={setOpen}
          id={btnData.id}
          deleteFun={deleteBanner}
          // deleteMsg={deleteMsg}
        />
      ) : (
        <></>
      )}
      {data.length > 4 ? (
        <Pagination
          className="flex w-full justify-end pr-2 itcem-center"
          count={totalPage}
          variant="outlined"
          shape="rounded"
          onChange={(e, value) => setPagValue(value)}
        />
      ) : (
        <></>
      )}
      {AddBtnCk ? (
        <BannerModal
          AddBtnCk={AddBtnCk}
          setAddBtnClk={setAddBtnClk}
          btnData={btnData}
          editableData={editableData}
          uploaded={uploadProgress}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Banner;
