import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../../context/PublicAPI/PublicApiContext";
import { useLocation } from "react-router-dom";

const AboutHero = () => {
  const path = useLocation().pathname;
  const { getbannerPublicApi, bannerdata } = useContext(PublicApiContext);
  useEffect(() => {
    getbannerPublicApi();
    // eslint-disable-next-line
  }, []);
  const finteredData = bannerdata.filter(
    (e) => e.name.toLowerCase() === path.substring(1)
  );
  const data = finteredData.sort((a, b) => b.id - a.id);

  return (
    data[0] && (
      <>
        <div className="relative h-[90vh] w-full">
          <img
            src={data[0]?.banner_image}
            alt="Hero section"
            className="w-full h-full object-cover absolute inset-0 "
            loading="lazy"
          />
          <div className="max-h-[90vh] flex-col w-full bg-[#24242490] absolute inset-0 flex justify-center items-center">
            <p className="text-2xl md:text-5xl w-[70%] md:w-[50%] text-center font-light leading-normal mt-16 capitalize font-poppins text-white tracking-wide">
              {data[0]?.title}
            </p>
            <p className="text-lg md:text-3xl w-[70%] md:w-[50%] text-center font-light leading-normal mt-3 capitalize font-poppins text-white tracking-wide">
              {data[0]?.slogan}
            </p>
          </div>
        </div>
      </>
    )
  );
};

export default AboutHero;
