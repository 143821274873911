import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";

const LeftCard = (props) => {
  const { faculty } = props;
  const navigate = useNavigate();
  const handelClick = (name) => {
    navigate(`/academics/${name}`);
  };

  return (
    <>
      <div className="min-w-[340px] bg-white max-h-[290px] h-full  rounded shadow-md">
        <div className="w-full bg-[#213C70] h-[60px] text-xl font-semibold text-white text-center flex justify-center items-center">
          Academics
        </div>

        <div className="w-[90%] py-3 m-auto">
          <FormControl className="w-full">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="bba"
                control={
                  <Radio className="scale-90" style={{ color: "#041962" }} />
                }
                label="BBA || Bachelor of Business Administration"
                className="py-2"
                onClick={() => handelClick("bba")}
                checked={faculty === "bba"}
              />
              <hr />
              <FormControlLabel
                value="bba-tt"
                control={
                  <Radio className="scale-90" style={{ color: "#041962" }} />
                }
                label="BBA-TT || Bachelor Of Business Administration In Travel And Tourism"
                className="py-2"
                onClick={() => handelClick("bba-tt")}
                checked={faculty === "bba-tt"}
              />
              <hr />
              <FormControlLabel
                value="bca"
                control={
                  <Radio className="scale-90" style={{ color: "#041962" }} />
                }
                label="BCA || Bachelor of Computer Application"
                className="py-2"
                onClick={() => handelClick("bca")}
                checked={faculty === "bca"}
              />
              <hr />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default LeftCard;
