import React, { useContext, useEffect } from "react";
import { chain } from "./chain";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const LifeAtCitizen = () => {
  const { galleryPublicApi, gallerydata } = useContext(PublicApiContext);

  const galleryData = gallerydata;

  useEffect(() => {
    document.title = "Citizen-Gallery";
    window.scrollTo(0, 0);
    galleryPublicApi();
    document.title = "Citizen College";
    // eslint-disable-next-line
  }, []);

  const FinalGalAblum = galleryData.filter(
    (e) => e.title_name.toLowerCase() === "home"
  );
  let sortedImages = FinalGalAblum[0]?.image_gallery_id.sort(
    (a, b) => b.id - a.id
  );
  return (
    FinalGalAblum[0]?.image_gallery_id.length >= 3 && (
      <>
        <div className="min-h-[80vh] bg-white ">
          <div className="flex justify-center items-center pt-14">
            <div className="md:my-5 my-3 text-4xl font-semibold border-b text-center font-abhaya text-[#041962] tracking-wide">
              Life At Citizen
            </div>
          </div>
          <div>
            <div
              id="images"
              className="md:h-[550px] h-full flex items-center flex-col md:flex-row justify-center gap-10 relative"
            >
              {/* <div className="hidden md:block"> */}
              <div className="absolute flex gap-10 top-0 ">
                <div className="lg:w-[400px] lg:h-[400px] w-[300px] h-[300px] z-10 bg-[#F8F8F8] rounded-full md:block hidden" />
                <div className="lg:w-[400px] lg:h-[400px] w-[300px] h-[300px] z-10 bg-[#F8F8F8] rounded-full md:block hidden" />
              </div>

              <div className="z-30">
                <img
                  src={sortedImages[0] && sortedImages[0].sub_gallery_image}
                  alt="image1"
                  className="w-[310px] h-[440px] object-cover rounded-3xl mt-12"
                  loading="lazy"
                />
              </div>
              <div className="z-30">
                <img
                  src={sortedImages[1] && sortedImages[1].sub_gallery_image}
                  alt="image2"
                  className="w-[310px] h-[440px] object-cover rounded-3xl md:mb-12"
                  loading="lazy"
                />
              </div>
              <div className="relative z-30">
                <Link
                  to="/gallery/photo-gallery"
                  className="absolute md:!top-2 bottom-4 h-4 w-full text-center underline font-abhaya text-[#041962] flex font-semibold items-center justify-center text-lg tracking-wide"
                >
                  {chain}
                  View More Information
                </Link>
                <img
                  src={sortedImages[2] && sortedImages[2].sub_gallery_image}
                  alt="image3"
                  className="w-[310px] mb-16 md:mb-0 h-[440px] object-cover rounded-3xl md:mt-12"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default LifeAtCitizen;
