import { Button, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import VideosContext from "../../../context/videos/VideosContext";
import "../css/Post.css";

const VideosModal = (props) => {
  const { AddBtnCk, setAddBtnClk, editData, btndata, uploaded } = props;
  const { name } = btndata;
  const { getvideos, postvideos, render, postSubvideos } =
    // const { videosData, getvideos, postvideos, render, postSubvideos } =
    useContext(VideosContext);
  const [data, setData] = useState({
    title: name === "update" ? editData[0].title_name : "",
    image: "",
  });
  const [image, setimage] = useState(null);
  const Album = {
    name: "None",
    id: -1,
  };
  // const [Album, setAlbum] = useState({
  //   name: "None",
  //   id: -1,
  // });
  const [errorMsg, setErrorMsg] = useState({
    status: true,
    msg: "",
    position: "",
  });
  const [videoError, setVideoError] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const imageDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // for Modal show or hide
  const ref = useRef();
  useEffect(() => {
    getvideos();
    if (AddBtnCk) {
      ref.current.click();
    } // eslint-disable-next-line
  }, [render]);

  if (uploaded === 100) {
    setTimeout(() => {
      setAddBtnClk(false);
      ref.current?.click();
    }, 1000);
  }

  const imageImageChange = (e) => {
    setimage(e.target.files[0]);
    const videoFormat = e.target.files[0].name.split(".").pop().toLowerCase();
    if (
      videoFormat !== "mp4" &&
      videoFormat !== "mov" &&
      videoFormat !== "webm"
    ) {
      setVideoError({
        status: false,
        msg: "Only MP4, MOV and WEBM Supported",
        position: "Image",
      });
    } else {
      setVideoError({ status: true, msg: "", position: "" });
    }
  };
  const MultyimageImageChange = (e) => {
    setimage(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      let videoFormat = e.target.files[i].name.split(".").pop().toLowerCase();
      if (
        videoFormat !== "mp4" &&
        videoFormat !== "mov" &&
        videoFormat !== "webm"
      ) {
        setVideoError({
          status: false,
          msg: "Only MP4, MOV and WEBM Supported",
          position: "Image",
        });
      } else {
        setVideoError({ status: true, msg: "", position: "" });
      }
    }
  };

  // const dropdownClk = (name, id) => {
  //   setAlbum({ name, id });
  // };
  const AddBtnClk = (e) => {
    e.preventDefault();
    if (Album.name === "None") {
      if (data.title.length <= 2) {
        setErrorMsg({
          status: true,
          msg: "Title Name Must Be Greater Than 2 Character.",
          position: "title",
        });
      } else if (image === null && !editData[0]) {
        setErrorMsg({
          status: true,
          msg: "Please Select Video",
          position: "Image",
        });
      } else {
        setErrorMsg({ status: false, msg: "", position: "" });
        if (Album.id === -1) {
          const finalData = { ...data, image: image };
          postvideos(finalData);
        } else {
          const finalData = { ...data, image: image, videos_id: Album.id };
          postSubvideos(finalData);
        }
        // setAddBtnClk(false);
        // ref.current.click();
      }
    } else {
      if (image === null && !editData[0]) {
        setErrorMsg({
          status: true,
          msg: "Please Select Video",
          position: "Image",
        });
      } else {
        setErrorMsg({ status: false, msg: "", position: "" });
        if (Album.id === -1) {
          const finalData = { ...data, image: image };
          postvideos(finalData);
        } else {
          const finalData = { ...data, image: image, videos_id: Album.id };
          postSubvideos(finalData);
        }
        // setAddBtnClk(false);
        // ref.current.click();
      }
    }

    // updated
    if (videoError.status) {
      if (errorMsg.status === false) {
        if (Album.id === -1) {
          const finalData = { ...data, image: image };
          postvideos(finalData);
        } else {
          const finalData = { ...data, image: image, videos_id: Album.id };
          postSubvideos(finalData);
        }
        setAddBtnClk(false);
        ref.current.click();
      }
    }
  };
  // const btnRef = useRef();
  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn d-none btn-primary text-black"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade modal-lg mt-[150px] "
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[30px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Upload Video
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col -mt-5 gap-3 text-black">
              <div className="mt-3" />
              {Album.id === -1 || Album.id === "" ? (
                <TextField
                  id="outlined-basic"
                  className="w-[90%] m-auto"
                  value={data.title}
                  onChange={imageDataChange}
                  name="title"
                  label="Album Name*"
                  variant="outlined"
                />
              ) : (
                ""
              )}
              {errorMsg.position === "title" ? (
                <p className="text-red-500 text-xs ml-16 -mt-4 w-[600px]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {Album.id !== -1 ? (
                <input
                  className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                  name="image"
                  multiple
                  onChange={MultyimageImageChange}
                  id="formFileLg"
                  type="file"
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
              ) : (
                <input
                  className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                  name="image"
                  onChange={imageImageChange}
                  id="formFileLg"
                  type="file"
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
              )}
              {errorMsg.position === "Image" ? (
                <p className="text-red-500 text-xs ml-16 -mt-4 w-[600px]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {videoError.position === "Image" ? (
                <p className="text-red-500 text-xs ml-16 -mt-4 w-[600px]">
                  **{videoError.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            {uploaded && (
              <div className="w-[85%] m-auto">
                <div className="progress mt-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploaded}%` }}
                  >
                    {`${uploaded}%`}
                  </div>
                </div>
              </div>
            )}
            I
            <div className="modal-footer pr-[50px] -mt-2">
              <Button
                type="submit"
                variant="contained"
                onClick={AddBtnClk}
                size="small"
                className="mb-2 mt-1 !capitalize"
              >
                Upload
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VideosModal;
