import React, { useContext, useEffect, useState } from "react";
import AboutHero from "../About/common/AboutHero";
import ContentHead from "../About/common/ContentHead";
import LeftCard from "./LeftCard";
import RightCard from "./RightCard";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { useParams } from "react-router-dom";

const Academic = () => {
  const { faculty } = useParams();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [facultyName, setFacultyName] = useState("bba");

  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    scrollToTop();
    document.title = "Citizen-Academic";
    // eslint-disable-next-line
  }, []);
  const filteredData = blogdata.filter(
    (e) => e.category_name.toLowerCase() === "academics"
  );

  const finaldata = filteredData.filter(
    (e) => e.sub_category?.toLowerCase() === facultyName
  );
  useEffect(() => {
    setFacultyName(faculty);
  }, [faculty]);

  return (
    <>
      <AboutHero />
      <ContentHead head={"Academics"} />
      <div className="w-[95%] flex-col md:flex-row m-auto min-h-[300px] !mt-14 flex gap-10 justify-between">
        <LeftCard faculty={faculty} />
        <RightCard
          facultyName={facultyName}
          data={finaldata[0] ? finaldata[0] : {}}
          blogdata={blogdata}
        />
      </div>
    </>
  );
};

export default Academic;
