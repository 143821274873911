import React, { useContext, useEffect, useState } from "react";
import Ads from "../Ads";
import BottomNav from "./BottomNav";
import BottomNavMobile from "./BottomNavMobile";
import TopNav from "./TopNav";
import { IoIosArrowUp } from "react-icons/io";
import PublicApiContext from "../../../context/PublicAPI/PublicApiContext";

const Navigation = () => {
  const {
    getcategoryPublicApi,
    categorydata,
    getAdvertisementPublicApi,
    advertisementData,
  } = useContext(PublicApiContext);
  const [showAdd, setShowAdd] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setShowAdd(1);
    }, 1000);
    getcategoryPublicApi();
    getAdvertisementPublicApi();
    // eslint-disable-next-line
  }, []);
  const revdata = advertisementData.sort((a, b) => b.id - a.id);
  const finalData = revdata.slice(0, 5);

  // ============ window scroll down =================================
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {finalData.map((e, index) => {
        return (
          showAdd === index + 1 && (
            <Ads setShowAdd={setShowAdd} image={e.banner_image} key={e.id} />
          )
        );
      })}
      <TopNav />
      {screenWidth > 1050 ? (
        <BottomNav data={categorydata} />
      ) : (
        <BottomNavMobile data={categorydata} />
      )}
      {scrollY > 570 && (
        <div className="TopShifter">
          <div
            onClick={scrollToTop}
            className=" fixed cursor-pointer rounded-sm duration-100 flex justify-center items-center top-[70vh] right-6 h-[30px] w-[30px] bg-[#041962] hover:bg-[#11162e] z-[50000000000000000000]"
          >
            <IoIosArrowUp className="text-white text-xl" />
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
