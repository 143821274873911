import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div>PageNotFound</div>
    </>
  );
};

export default PageNotFound;
