import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState } from "react";
import CategoryContext from "./CategoryContext";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const CategoryState = (props) => {
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  const [rendersubCategoryData, setRendersubCategoryData] = useState(false);
  // ================================ for category ========================================
  // for category get request
  const getCategories = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/category/category`,
        {
          headers,
        }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  // for category post request
  const postCategories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/category`,
        data,
        { headers }
      );
      if (response.status === 201) {
        toast.success(
          <div className="text-[15px]">Category successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.display_order) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.display_order[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else {
          toast.error(<div className="text-[14px]">Some error accuired</div>, {
            icon: <BiErrorCircle className="text-xl " />,
          });
        }
      }
    }
  };

  //   for delete category
  const deleteCategory = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/category/category/${id}`,
        { headers }
      );
      if (response.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            Category successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //for update category
  const UpdateCategory = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/category/category/${id}`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Advertisement successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.category_name}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // ================================ for sub-category ========================================
  const [SubCategoryData, setSubCategoryData] = useState([]);
  // for category get request
  const getSubCategories = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key`,
        { headers }
      );
      setRenderData(!renderData);
      await setSubCategoryData(response.data);
    } catch (e) {}
  };

  // for subCategory delete request
  const deleteSubCategory = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key/${id}`,
        { headers }
      );
      if (res.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            SubCategory successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRendersubCategoryData(!rendersubCategoryData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // for sub_category post request
  const postSub_Categories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Subcategory successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRendersubCategoryData(!rendersubCategoryData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.display_order) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.display_order[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.category_key_name[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // for sub_category post request
  const updateSub_Categories = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key/${id}`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            SubCategory successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRendersubCategoryData(!rendersubCategoryData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.display_order) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.display_order[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.category_key_name[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };
  // ================================ for sub-sub-category ========================================
  const [subSubCategory, setsubSubCategory] = useState([]);
  const [rendersubSubCategoryData, setRendersubSubCategoryData] =
    useState(false);
  // for category get request
  const getSubSubCategories = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/category/sub-sub-category-key`,
        { headers }
      );
      setRenderData(!renderData);
      await setsubSubCategory(response.data);
    } catch (e) {}
  };

  // for subCategory delete request
  const deleteSubSubCategory = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/category/sub-sub-category-key/${id}`,
        { headers }
      );
      if (res.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            SubSubCategory successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRendersubSubCategoryData(!rendersubSubCategoryData);
      } else {
        // toast.error("Soe error accuired");
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">
            {e.response.data.sub_category_key_name[0]}
          </div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // for category post request
  const postSubSubCategories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/sub-sub-category-key`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">
            SubSubCategory successfully created
          </div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRendersubSubCategoryData(!rendersubSubCategoryData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.display_order) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.display_order[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.sub_category_key_name[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // for subsubcategory update request
  const updateSubSubCategories = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/category/sub-sub-category-key/${id}`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            SubSubCategory successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRendersubSubCategoryData(!rendersubSubCategoryData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.display_order) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.display_order[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.sub_category_key_name[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // setsubSubCategory(!subSubCategory)
  return (
    <>
      <CategoryContext.Provider
        value={{
          getCategories,
          updateSub_Categories,
          postCategories,
          data,
          deleteCategory,
          UpdateCategory,
          getSubCategories,
          SubCategoryData,
          setSubCategoryData,
          deleteSubCategory,
          postSub_Categories,
          renderData,
          setRenderData,
          rendersubCategoryData,
          subSubCategory,
          setRendersubSubCategoryData,
          rendersubSubCategoryData,
          getSubSubCategories,
          deleteSubSubCategory,
          postSubSubCategories,
          updateSubSubCategories,
        }}
      >
        {props.children}
      </CategoryContext.Provider>
    </>
  );
};

export default CategoryState;
