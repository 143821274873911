import React, { useEffect } from "react";
import { closeBtn } from "./Close";
import { Link } from "react-router-dom";

const Ads = (props) => {
  const { setShowAdd, image } = props;
  const btnclk = () => {
    setShowAdd((p) => p + 1);
  };
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);
  return (
    <>
      {String(image.length) > 10 && (
        <div className="image fixed h-full w-screen z-[5000000000] inset-0 flex justify-center items-center bg-[#000000b0]">
          <div className="min-h-[80px] max-h-[80vh] mx-3 min-w-[300px] max-w-[550px] border-[10px] border-white shadow relative">
            <div
              onClick={btnclk}
              className="absolute -top-5 -right-5 bg-slate-200 z-[55000000000] rounded-full cursor-pointer hover:bg-white"
            >
              {closeBtn}
            </div>
            <div className="min-w-[300px] md:min-h-[200px] min-h-[80px]">
              <img
                src={image}
                alt="add"
                className=" h-full max-h-[70vh] w-full object-contain shadow"
                loading="lazy"
              />
            </div>
            <div className="w-full bg-white flex pt-2 justify-end gap-6">
              {/* <div className="shadow-md cursor-pointer hover:from-[#012D72] hover:to-[#012D72] duration-200 p-[8px] px-5 flex justify-center items-center text-sm rounded-lg bg-gradient-to-r from-[#0288A9] to-[#012D72] text-white">
              Apply Now
            </div> */}
              <Link
                to="/contact"
                onClick={btnclk}
                className="shadow-md cursor-pointer hover:from-[#012D72] hover:to-[#012D72] duration-200 p-[8px] px-5 flex justify-center items-center text-sm rounded-lg bg-gradient-to-r from-[#0288A9] to-[#012D72] text-white"
              >
                Enquiry
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ads;
