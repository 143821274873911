import React, { useContext, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import "../css/Post.css";
import BlogsModal from "../Modals/BlogsModal";
import BlogContext from "../../../context/Blog/BlogContext";
import NewCategoryContext from "../../../context/NewCategory/NewCategoryContext";
import CategoryContext from "../../../context/category/CategoryContext";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Posts = () => {
  const [openModal, setOpenModal] = useState(false);
  const { data, getBlogs, deleteblog, renderData, uploadProgress } =
    useContext(BlogContext);
  const { getSubCategories, getSubSubCategories } = useContext(CategoryContext);
  const { getNewCategory } = useContext(NewCategoryContext);

  const [editData, setEditData] = useState([]);
  const [btndata, setBtnData] = useState({ name: "add", id: "-1" });

  useEffect(() => {
    getBlogs();
    getNewCategory();
    getSubCategories();
    getSubSubCategories();
    if (!openModal) {
      setEditData([]);
    } // eslint-disable-next-line
  }, [renderData, openModal]);
  const updateBtnClk = (id) => {
    setOpenModal(true);
    setBtnData({ name: "update", id: id });
    const edtData = data.filter((e) => e.id === id);
    setEditData(edtData);
  };
  const SubCategoryAddBtnCLk = () => {
    setOpenModal(true);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };

  const shortedData = data.sort((a, b) => b.id - a.id);

  const [pagValue, setPagValue] = useState(1);
  const paginationValue = 10;
  const totalData = data.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = shortedData.filter((e) =>
    e.blog_title.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-full overflow-y-auto ml-0 md:ml-[265px] mt-10">
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Blog
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Title
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Category
                </th>
                <th className="border p-1" style={{ width: "310px" }}>
                  Description
                </th>
                <th className="border p-1" style={{ width: "310px" }}>
                  Image
                </th>
                <th className="border p-1" style={{ width: "65px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {shortedData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "40px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[50px] w-[4%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border min-w-[100px] w-[20%] px-3 disLim !text-start">
                            {e.blog_title}
                          </td>
                          <td className="border min-w-[100px] w-[15%] px-3">
                            {e.category_name}
                          </td>
                          <td className="border min-w-[100px] w-[40%] px-3 py-[4px] my-2 disLim ">
                            <div
                              className="text-justify"
                              dangerouslySetInnerHTML={{ __html: e.blog_post }}
                            />
                          </td>
                          {/* <td className='border p-1' style={{ width: '100px' }}>{() => FindCategory(e)}</td> */}
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            <img
                              src={e.image}
                              alt=""
                              className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                              style={{ objectFit: "cover" }}
                              loading="lazy"
                            />
                          </td>
                          <td className="flex items-center justify-center p-1 relative !w-[100px] m-auto">
                            {/* <div className="m-auto"> */}
                            <button
                              className="edit actionIcons  absolute left-5 text-xl"
                              onClick={() => updateBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons absolute right-5">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                            {/* </div> */}
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "40px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[50px] w-[4%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border min-w-[100px] w-[20%] px-3 disLim">
                            {e.blog_title.substring(0, 20)}
                          </td>
                          <td className="border min-w-[100px] w-[15%] px-3">
                            {e.category_name}
                          </td>
                          <td className="border min-w-[100px] w-[40%] px-3 py-[4px] my-2 disLim ">
                            <div
                              className="text-justify"
                              dangerouslySetInnerHTML={{ __html: e.blog_post }}
                            />
                          </td>
                          {/* <td className='border p-1' style={{ width: '100px' }}>{() => FindCategory(e)}</td> */}
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            <img
                              src={e.image}
                              alt=""
                              className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                              style={{ objectFit: "cover" }}
                              loading="lazy"
                            />
                          </td>
                          <td className="flex items-center justify-center p-1 relative !w-[100px] m-auto">
                            {/* <div className="m-auto"> */}
                            <button
                              className="edit actionIcons  absolute left-5 text-xl"
                              onClick={() => updateBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons absolute right-5">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                            {/* </div> */}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btndata.id}
              deleteFun={deleteblog}
            />
          ) : (
            <></>
          )}
          {data.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {openModal ? (
          <BlogsModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            btndata={btndata}
            setBtnData={setBtnData}
            editData={editData}
            uploaded={uploadProgress}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Posts;
