import React, { useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const EachVideos = (props) => {
  const { imageClk, url } = props;
  const a = document.querySelectorAll(".VIdeo");
  if (!imageClk) {
    if (a !== null) {
      a.forEach((e) => e.pause());
    }
  }
  useEffect(() => {
    const video = document.getElementById("vID1");
    video.play();
  }, []);

  return (
    <div className="!w-full !h[100vh] fixed flex justify-center items-center bg-gray-800">
      <video
        controls
        id="vID1"
        className="VIdeo md:mx-auto !px-5 !pb-10 !w-[1050px] !h-[100vh]  object-contain lg:opacity-80 select-none"
      >
        <source
          src={url}
          type="video/mp4"
          className="absolute top-0 bottom-0 right-0 left-0 h-full w-full object-contain lg:opacity-80 select-none"
        />
      </video>
    </div>
  );
};

export default EachVideos;
