import React from "react";
import { Link } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";

const EachNoticeCatd = (props) => {
  const { data } = props;

  return (
    <>
      <div className="min-w-[240] relative !h-[200px] max-w-[300px] pt-4 pb-2  bg-[#F4F4F4] rounded-xl shadow-lg ">
        <div className="px-2">
          <h3 className=" font-abhaya font-semibold text-xl tracking-wide mt-1 text-[#5F5F5F]">
            {data.created_date_bs}
          </h3>
          <div
            className="!h-[100px] overflow-hidden !text-justify"
            dangerouslySetInnerHTML={{
              __html:
                data.notice_post.length > 115
                  ? data.notice_post.slice(0, 115) + "..."
                  : data.notice_post,
            }}
          ></div>
        </div>
        <div className="flex justify-center items-center absolute flex-col bottom-2 w-full ">
          <div className="w-full mt-1 h-[1px] bg-[#C9C9C9]" />
          <Link
            to={`/notice/${data.id}`}
            className="mx-auto flex items-center gap-1 hover:text-blue-950 font-abhaya  justify-center text-center w-full mt-[6px]"
          >
            <AiFillEye className="text-lg" />
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default EachNoticeCatd;
