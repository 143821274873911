import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import VideosCards from "./VideosCards";
import "./EachImage.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const Videos = () => {
  const { videodata, videoPublicApi } = useContext(PublicApiContext);
  const videosData = videodata;
  useEffect(() => {
    document.title = "Citizen-Videos";
    window.scrollTo(0, 0);
    videoPublicApi();
    document.title = "Citizen-Video";
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-[50vh] h-full py-5 overflow-hidden gallery max-w-[1100px] m-auto">
      <section className="overflow-hidden text-gray-700 ">
        <div className=" container px-0 py-2 mx-auto lg:pt-12 lg:px-32 flex items-center justify-center">
          <div className="flex flex-wrap -m-1 md:-m-2 gap-4 items-center justify-center md:items-start md:justify-start">
            {videosData.map((e) => {
              return <VideosCards elm={e} key={e.id} />;
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Videos;
