import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import logo from "../../../images/logo.jpg";

const BottomNav = (props) => {
  const data = props.data;
  const loc = useLocation().pathname;

  // making url
  function convertToSlug(inputString) {
    const lowercaseString = inputString.toLowerCase();
    const hyphenatedString = lowercaseString.replace(/\s+/g, "-");
    return hyphenatedString;
  }

  const filterData = data.filter(
    (e) => e.category_name.toLowerCase() !== "home"
  );

  const sortedData = filterData.sort(
    (a, b) => a.display_order - b.display_order
  );
  const finalData = sortedData.filter((e) => e.active === true);

  const locSub = loc.split("/");

  return (
    <div className="h-[60px] bg-white flex items-center justify-center sticky -top-[1px] z-[40] shadow-lg">
      <nav className="flex  h-full xl:gap-10 w-full items-center justify-between">
        <Link
          to={"/"}
          className="w-[18%] h-full flex justify-center items-center"
        >
          <img src={logo} alt="logo" className="max-h-[60px]" loading="lazy" />
        </Link>
        <div className="bg-[#041962] w-[80%] h-full flex justify-end">
          <ul className="flex h-full px-20 justify-around gap-1 xl:w-[90%] w-full tracking-wider !font-abhaya items-center bg-[#041962]">
            <li className="flex items-center justify-center flex-col">
              <Link
                to="/"
                className="font-poppins text-[.9rem] text-white py-1"
              >
                Home
              </Link>
              {loc === "/" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            {finalData.map((e) => {
              return (
                <li className="relative group" key={e.id}>
                  <Link
                    className="flex items-center gap-1 text-[.9rem] font-poppins text-white py-1"
                    to={
                      (e.category_key.length > 0 &&
                        e.category_name.toLowerCase() !== "") > 0
                        ? undefined
                        : convertToSlug(e.category_name)
                    }
                  >
                    {e.category_name}
                    {e.category_key.length > 0 &&
                      e.category_name.toLowerCase() !== "" && (
                        <IoIosArrowDown className="text-sm" />
                      )}
                  </Link>
                  {e.category_key.length > 0 &&
                    e.category_name.toLowerCase() !== "" && (
                      <ul className="absolute z-50 hidden shadow-md group-hover:block min-w-[170px]">
                        <li className="flex flex-col bg-white shadow-md font-poppins text-sm w-full">
                          {e.category_key.map((elm) => (
                            <Link
                              to={`${convertToSlug(
                                elm.category_name
                              )}/${convertToSlug(elm.category_key_name)}`}
                              className={`p-2 !py-[10px] text-[.8rem] border-b border-slate-500 capitalize hover:bg-[#041962] ${
                                locSub[2] ===
                                  convertToSlug(elm.category_key_name) &&
                                "bg-[#17224a] text-white"
                              } hover:text-white`}
                              key={elm.id}
                            >
                              {elm.category_key_name}
                            </Link>
                          ))}
                        </li>
                      </ul>
                    )}
                  {loc.includes(convertToSlug(e.category_name)) && (
                    <div className="w-full h-[3px] bg-slate-300 rounded" />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default BottomNav;
