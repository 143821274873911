import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import Intro from "./Intro";
import Message from "./Message";
import NewsEvent from "./NewsEvent";
import Degree from "./Degree";
import Testimonials from "./Testimonials";
import LifeAtCitizen from "./LifeAtCitizen";
import Notice from "./Notice";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen College";
  }, []);
  return (
    <>
      <HeroSection />
      <Intro />
      <Message />
      <NewsEvent />
      <Notice />
      <Degree />
      <Testimonials />
      <LifeAtCitizen />
    </>
  );
};

export default Home;
