import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const LeftCard = (props) => {
  const { data, selectedValue, setSelectedValue } = props;
  return (
    data.length > 0 && (
      <>
        <div className="min-w-[340px] bg-white min-h-[150px] mb-10 h-full  rounded shadow-md">
          <div className="w-full bg-[#213C70] h-[60px] text-xl font-semibold text-white text-center flex justify-center items-center">
            Teams’
          </div>

          <div className="w-[90%] py-3 m-auto">
            <FormControl className="w-full">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                name="radio-buttons-group"
              >
                {data.map((e) => (
                  <div key={e.id}>
                    <FormControlLabel
                      value={e.id}
                      onClick={() => setSelectedValue(e.id)}
                      control={
                        <Radio
                          className="scale-75"
                          style={{ color: "#041962" }}
                        />
                      }
                      label={e.name}
                      // label={e.sub_category_key_name}
                    />
                    <hr />
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </>
    )
  );
};

export default LeftCard;
