import React, { useState } from "react";
import NewCategoryContext from "./NewCategoryContext";
import axios from "axios";
import Domain from "../Domain";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const NewCategoryState = (props) => {
  const DomainUrl = Domain();
  const [renderData, setRenderData] = useState(false);

  const [newCatdata, setnewCatdata] = useState([]);
  // for category get request
  const getNewCategory = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/category/category`,
        {
          headers,
        }
      );
      const data = await response.data;
      setnewCatdata(data);
    } catch (e) {}
  };

  // for category post request
  const postNewCategories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/category`,
        data,
        { headers }
      );
      if (response.status === 201) {
        toast.success(
          <div className="text-[15px]">Category successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">{e.response.data}</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };
  // for sub-category post request
  const postNewsubCategories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key`,
        data,
        { headers }
      );
      if (response.status === 201) {
        toast.success(
          <div className="text-[15px]">Category successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">
            {e.response.data.category_key_name[0]}
          </div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // for subsub-category post request
  const postNewsubsubCategories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/category/sub-sub-category-key`,
        data,
        { headers }
      );
      if (response.status === 201) {
        // toast.success("Category Added");
        toast.success(
          <div className="text-[15px]">Category successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        // toast.error("An error occured!");
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">
            {e.response.data.sub_category_key_name[0]}
          </div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  const deleteNewCategory = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/category/category/${id}`,
        { headers }
      );
      if (response.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            Category successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">
            {" "}
            {e.response.data.category_name[0]}
          </div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  const updateNewCategory = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/category/category/${id}`,
        data,
        { headers }
      );
      if (response.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Category successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.category_name}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };
  const updateNewsubCategory = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/category/sub-category-key/${id}`,
        data,
        { headers }
      );
      if (response.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Category successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">
            {e.response.data.category_key_name[0]}
          </div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  return (
    <>
      <NewCategoryContext.Provider
        value={{
          getNewCategory,
          newCatdata,
          postNewCategories,
          postNewsubCategories,
          postNewsubsubCategories,
          renderData,
          deleteNewCategory,
          updateNewCategory,
          updateNewsubCategory,
        }}
      >
        {props.children}
      </NewCategoryContext.Provider>
    </>
  );
};

export default NewCategoryState;
