import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Message = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      e.sub_category.toLowerCase() === "slug-2"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData[0];

  return (
    finalData && (
      <>
        <div className=" h-full py-10 flex justify-center items-center">
          <div className="flex w-[95%] m-auto items-center justify-around mt-10">
            <div className="flex items-center justify-around md:flex-row flex-col">
              <div className="md:w-[70%] w-full flex flex-col gap-1 items-start">
                <h3 className="text-[#041962] font-semibold text-2xl tracking-wide border-b mb-1">
                  {finalData.blog_title}
                </h3>
                <h4 className="text-[#000] font-semibold uppercase text-xl tracking-wide">
                  {finalData.author_name}
                </h4>
                <div className="text-justify pr-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        finalData.blog_post?.length > 755
                          ? finalData.blog_post.slice(0, 755) + "..."
                          : finalData.blog_post,
                    }}
                  ></div>
                </div>
                {finalData.blog_post?.length > 755 && (
                  <Link
                    to={`home/${finalData.id}`}
                    className="bg-transparent text-black p-[4px] mt-2 border tracking-wide hover:bg-[#041962] hover:text-white md:px-4 px-3 text-sm md:text-base duration-150 rounded"
                  >
                    View More
                  </Link>
                )}
              </div>
              <div>
                <img
                  src={finalData.image}
                  alt="intro"
                  className="md:h-[370px] mt-5 h-full md:w-[285px] w-full object-cover rounded-2xl"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Message;
