import React, { useContext, useEffect } from "react";
import ContentHead from "../common/ContentHead";
import PublicApiContext from "../../../../../context/PublicAPI/PublicApiContext";

const SirMsg = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) => e.category_name.toLowerCase() === "about us"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData;

  const message =
    finalData.length > 0 &&
    finalData.filter((e) => e.sub_category.toLowerCase().includes("message"));

  return (
    message[0] && (
      <>
        <div className="min-h-[60vh]">
          <ContentHead head={"Message From Principal"} />
          <div className="w-[95%] xl:w-[80%]  max-w-[1050px] description-container !mt-10">
            {message[0].image && (
              <img
                src={message[0]?.image}
                alt="this is name"
                className="p-2 !ml-3 !w-full sm:!w-auto !max-w-[600px] min-w-[300px] sm:!max-w-[350px] !rounded img-responsive floating-image right !m-auto"
                loading="lazy"
              />
            )}

            <div
              className="md:text-lg text-justify"
              dangerouslySetInnerHTML={{ __html: message[0]?.blog_post }}
            />
          </div>
        </div>
      </>
    )
  );
};

export default SirMsg;
