import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { useParams } from "react-router-dom";
import EachNoticeSlug from "./EachNoticeSlug";

const NoticeIdSlug = () => {
  const data = useParams().id;
  const { onenoticePublicApi, onenoticedata } = useContext(PublicApiContext);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    onenoticePublicApi(data);
    scrollToTop();
    document.title = "Citizen College";
    // eslint-disable-next-line
  }, []);
  return onenoticedata[0] && <EachNoticeSlug data={onenoticedata[0]} />;
};

export default NoticeIdSlug;
