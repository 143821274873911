import React, { useContext, useEffect } from "react";
import AboutHero from "../About/common/AboutHero";
import ContentHead from "../About/common/ContentHead";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { useParams } from "react-router-dom";
import EachPostSlug from "./EachPostSlug";

const CategorySlug = () => {
  const category = useParams().category;
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen College";
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);

  // making url
  function convertToSlug(inputString) {
    const lowercaseString = inputString.toLowerCase();
    const hyphenatedString = lowercaseString.replace(/\s+/g, "-");
    return hyphenatedString;
  }

  const data = blogdata.filter(
    (e) => convertToSlug(e.category_name) === category
  );
  const oneblogdata = data[data.length - 1];

  return (
    <div className="min-h-[30vh]">
      <AboutHero />
      <ContentHead />
      {oneblogdata && <EachPostSlug data={oneblogdata} />}
    </div>
  );
};

export default CategorySlug;
