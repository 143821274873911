import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import NoticeContext from "../../../context/notice/NoticeContext";
import { CKEditor } from "ckeditor4-react";

const NoticeModal = (props) => {
  const { modalOpen, setModalOpen, updateData, btnNameID, uploaded } = props;
  const { btnName, id } = btnNameID;
  const { postNotice, UpdateNotice } = useContext(NoticeContext);
  const [inputfield, setInputfield] = useState({
    notice_name: btnName === "editBtn" ? updateData[0].notice_name : "",
    notice_post: btnName === "editBtn" ? updateData[0].notice_post : "",
    display_order: "1",
    active: true,
  });
  const [checkedBox, setCheckedBox] = useState(true);
  const num = btnName === "editBtn" ? updateData[0].display_order : 1;

  const [disData, setDisData] = useState(
    btnName === "editBtn" ? updateData[0].notice_post : ""
  );
  const [image, setImage] = useState("");
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const teacherImageChange = (e) => {
    setImage(e.target.files[0]);
    let a = e.target.files[0].name.split(".").pop().toLowerCase();
    if (a !== "png" && a !== "jpg" && a !== "jpeg") {
      setimgErrorMsg({
        status: true,
        msg: "Image extension can only be in png and jpg",
        position: "img",
      });
    } else {
      setimgErrorMsg({ status: false, msg: "", position: "" });
    }
  };
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const inputfldChange = (e) => {
    setInputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const ref = useRef();
  useEffect(() => {
    if (modalOpen) {
      ref.current.click();
    } // eslint-disable-next-line
  }, []);

  const ChecKBoxChange = (e) => {
    let isChecked = e.target.checked;
    setCheckedBox(isChecked);
  };

  // const handleChange1 = (e) => {
  //   const regex = /^[0-9\b]+$/;
  //   if (e.target.value === "" || regex.test(e.target.value)) {
  //     setNum(e.target.value);
  //   }
  // };
  if (uploaded === 100) {
    setTimeout(() => {
      setModalOpen(false);
      ref.current?.click();
    }, 1000);
  }

  const addBtnClk = (e) => {
    e.preventDefault();
    if (inputfield.notice_name < 5) {
      setErrorMsg({
        status: true,
        msg: "Notice Name Must Be Greater Than 5.",
        position: "name",
      });
    } else if (disData < 5) {
      setErrorMsg({
        status: true,
        msg: "Notice Discription Must Be Greater Than 5.",
        position: "dis",
      });
    }
    //  else if (num < 1) {
    //   setErrorMsg({
    //     status: true,
    //     msg: "Please Enter Display Order",
    //     position: "dOrder",
    //   });
    // }
    else {
      setErrorMsg({ status: false, msg: "", position: "" });
      const finaldata = {
        ...inputfield,
        active: checkedBox,
        display_order: num,
        notice_post: disData,
      };
      if (btnName === "editBtn") {
        UpdateNotice(finaldata, image, id);
      } else {
        postNotice(finaldata, image);
      }
      // setModalOpen(false);
      // ref.current.click();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        ref={ref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <form className="modal-content m-auto">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[75px] absolute top-4 text-lg text-black font-semibold tracking-wider"
                id="exampleModalLabel"
              >
                Create Notice
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-2 NoticeModal noticeCkeditor">
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="notice_name"
                value={inputfield.notice_name}
                onChange={inputfldChange}
                label="Notice Name*"
                variant="outlined"
              />
              {errorMsg.position === "name" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <CKEditor
                className="bg-red-500 text-red-500"
                initData={
                  btnName === "editBtn" ? updateData[0].notice_post : ""
                }
                onChange={(event) => {
                  const data = event.editor.getData();
                  setDisData(data);
                }}
              />
              {errorMsg.position === "dis" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <input
                className="form-control form-control-lg text w-[80%] rounded-1 shadow-sm"
                name="image"
                onChange={teacherImageChange}
                id="formFileLg"
                type="file"
                style={{ fontSize: "16px", paddingTop: "10px" }}
              />
              {imgErrorMsg.position === "img" ? (
                <p className="text-red-500 text-xs w-[80%] ml-8 -mt-2">
                  **{imgErrorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {/* <TextField
                label="Display-Order*"
                name="display_order"
                value={num}
                onChange={handleChange1}
                variant="outlined"
                className="w-[80%] m-auto"
              />
              {errorMsg.position === "dOrder" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}
              {btnName === "editBtn" ? (
                <>
                  {updateData[0].active ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => ChecKBoxChange(e)}
                        />
                      }
                      label="Notice Active"
                      className="text-gray-500 tracking-wider w-[80%] -mt-2"
                    />
                  ) : (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => ChecKBoxChange(e)} />}
                      label="Notice Active"
                      className="text-gray-500 tracking-wider w-[80%] -mt-2"
                    />
                  )}
                </>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => ChecKBoxChange(e)}
                    />
                  }
                  label="Notice Active"
                  className="text-gray-500 tracking-wider w-[80%] -mt-2"
                />
              )}
            </div>
            {uploaded && uploaded > 0 && (
              <div className="w-[80%] m-auto">
                <div className="progress my-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploaded}%` }}
                  >
                    {`${uploaded}%`}
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer flex mr-[80px] mb-2">
              <Button
                type="submit"
                variant="contained"
                className=" mt-1 !capitalize"
                onClick={addBtnClk}
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NoticeModal;
