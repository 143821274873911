import { Button, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import SubjectNameContext from "../../../context/SubjectName/SubjectNameContext";

import "../css/Post.css";

const SubjectNameModal = (props) => {
  const { AddBtnCk, setAddBtnClk, editData, btndata } = props;
  const { name, id } = btndata;
  const { postsubjectName, updatesubjectName } = useContext(SubjectNameContext);
  const [data, setData] = useState({
    title: name === "update" ? editData[0].subject_name : "",
    subName: name === "update" ? editData[0].subject_name : "",
    className: "",
  });

  const streamCHeck = (e) => {
    if (e === 1) return "bba";
    else if (e === 2) return "bba-tt";
    else return "bca";
  };

  const [category, setcategory] = useState({
    name: name === "update" ? streamCHeck(editData[0].stream_name) : "",
    id: name === "update" ? editData[0].stream_name : "",
  });
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const checkGradesem = (id) => {
    switch (id) {
      case 1:
        return "1st semester";
      case 2:
        return "2nd semester";
      case 3:
        return "3rd semester";
      case 4:
        return "4th semester";
      case 5:
        return "5th semester";
      case 6:
        return "6th semester";
      case 7:
        return "7th semester";
      case 8:
        return "8th semester";
      default:
        break;
    }
  };
  const checkYear = (id) => {
    switch (id) {
      case 1:
        return "1st Year";
      case 2:
        return "2nd Year";
      case 3:
        return "3rd Year";
      case 4:
        return "4th Year";
      default:
        break;
    }
  };

  const [className, setClassName] = useState(
    editData[0]
      ? {
          name: checkGradesem(editData[0].sem_type),
          id: editData[0].sem_type,
        }
      : { name: "", id: "" }
  );
  const [yearcls, setYearCls] = useState(
    editData[0]
      ? { name: checkYear(editData[0].year_type), id: editData[0].year_type }
      : { name: "", id: "" }
  );

  const noteDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  // for Modal show or hide
  const ref = useRef();
  useEffect(() => {
    // getSubSubCategories();
    if (AddBtnCk) {
      ref.current.click();
      if (name !== "update") {
        setClassName({ name: "", id: "" });
        setYearCls({ name: "", id: "" });
      } else {
      }
    } // eslint-disable-next-line
  }, []);

  const dropdownClk = (name, id) => {
    setYearCls({ name: "", id: "" });
    setClassName({ name: "", id: "" });
    setcategory({ name, id });
  };

  const AddBtnClk = (e) => {
    e.preventDefault();
    if (category.id <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please Choose Category",
        position: "category",
      });
    } else if (className.id === "" && yearcls.id === "") {
      setErrorMsg({
        status: true,
        msg: "Please Select Grade",
        position: "grade",
      });
    } else if (data.subName.length <= 2) {
      setErrorMsg({
        status: true,
        msg: "Subject Name Must be Greater Than 2 Character.",
        position: "subjName",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (id === -1) {
        const finalData = {
          ...data,
          category: category.id,
          className: className.id,
          yearcls: yearcls.id,
        };
        postsubjectName(finalData);
      } else {
        const finalData = {
          ...data,
          category: category.id,
          className: className.id,
          yearcls: yearcls.id,
        };
        updatesubjectName(finalData, id);
      }
      ref.current.click();
      setAddBtnClk(false);
    }
  };
  const btnRef = useRef();

  const subSubCategorydata = [
    {
      sub_category_key_name: "BBA",
      id: 1,
    },
    {
      sub_category_key_name: "BBA-TT",
      id: 2,
    },
    {
      sub_category_key_name: "BCA",
      id: 3,
    },
  ];

  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn d-none btn-primary text-black"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade modal-lg mt-[100px] "
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[30px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Create Subject
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-3 text-black">
              <div className="dropdown dropdownbtn galleryDropdown">
                <div
                  className="btn btn-secondary dropdownbtn relative galleryMidalDropdown !pt-[10px]"
                  id="dropdownMenuButton1"
                  ref={btnRef}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {category.name === "" ? "--Select Stream--" : category.name}
                  <AiOutlineCaretDown className="absolute right-3 top-5" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {subSubCategorydata.map((e) => {
                    return (
                      <li
                        className="category"
                        value={e.id}
                        name="None"
                        key={e.id}
                        onClick={() =>
                          dropdownClk(e.sub_category_key_name, e.id)
                        }
                      >
                        {e.sub_category_key_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              {errorMsg.position === "category" ? (
                <p className="text-red-500 text-xs ml-16 -mt-3 w-[600px]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {category.name.slice(0, 3).toLowerCase() === "bca" ||
              category.name.slice(0, 3).toLowerCase() === "bba-tt" ||
              category.name.slice(0, 3).toLowerCase() === "bba" ? (
                <div className="dropdown dropdownbtn galleryDropdown">
                  <div
                    className="btn btn-secondary dropdownbtn relative galleryMidalDropdown !pt-[10px]"
                    id="dropdownMenuButton1"
                    ref={btnRef}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {className.name === ""
                      ? "--Select Grade--"
                      : className.name}
                    <AiOutlineCaretDown className="absolute right-3 top-5" />
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      className="category"
                      name="None"
                      onClick={() =>
                        setClassName({ name: "1st semester", id: "1" })
                      }
                    >
                      1st semester
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() =>
                        setClassName({ name: "2nd semester", id: "2" })
                      }
                    >
                      2nd semester
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() =>
                        setClassName({ name: "3rd semester", id: "3" })
                      }
                    >
                      3rd semester
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() =>
                        setClassName({ name: "4th semester", id: "4" })
                      }
                    >
                      4th semester
                    </li>
                    {category.name.slice(0, 3).toLowerCase() !== "mbs" ? (
                      <>
                        <li
                          className="category"
                          name="None"
                          onClick={() =>
                            setClassName({ name: "5th semester", id: "5" })
                          }
                        >
                          5th semester
                        </li>
                        <li
                          className="category"
                          name="None"
                          onClick={() =>
                            setClassName({ name: "6th semester", id: "6" })
                          }
                        >
                          6th semester
                        </li>
                        <li
                          className="category"
                          name="None"
                          onClick={() =>
                            setClassName({ name: "7th semester", id: "7" })
                          }
                        >
                          7th semester
                        </li>
                        <li
                          className="category"
                          name="None"
                          onClick={() =>
                            setClassName({ name: "8th semester", id: "8" })
                          }
                        >
                          8th semester
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <li className="category" value={e.id} name='None' key={e.id} onClick={() => dropdownClk(e.sub_category_key_name, e.id)}>{e.sub_category_key_name}</li> */}
                  </ul>
                </div>
              ) : (
                <div className="dropdown dropdownbtn galleryDropdown">
                  <button
                    className="btn btn-secondary dropdownbtn relative galleryMidalDropdown"
                    id="dropdownMenuButton1"
                    ref={btnRef}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {yearcls.name === "" ? "--Select Grade--" : yearcls.name}
                    <AiOutlineCaretDown className="absolute right-3 top-5" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      className="category"
                      name="None"
                      onClick={() => setYearCls({ name: "1st Year", id: "1" })}
                    >
                      1st Year
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() => setYearCls({ name: "2nd Year", id: "2" })}
                    >
                      2nd Year
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() => setYearCls({ name: "3rd Year", id: "3" })}
                    >
                      3rd Year
                    </li>
                    <li
                      className="category"
                      name="None"
                      onClick={() => setYearCls({ name: "4th Year", id: "4" })}
                    >
                      4th Year
                    </li>
                  </ul>
                </div>
              )}
              {errorMsg.position === "grade" ? (
                <p className="text-red-500 text-xs ml-16 -mt-4 w-[600px]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.subName}
                onChange={noteDataChange}
                name="subName"
                label="Subject Name*"
                variant="outlined"
              />
              {errorMsg.position === "subjName" ? (
                <p className="text-red-500 text-xs ml-16 -mt-4 w-[600px]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer pr-[50px]">
              <Button
                type="submit"
                variant="contained"
                onClick={AddBtnClk}
                size="small"
                className="mb-2 mt-1 !capitalize"
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubjectNameModal;
