import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import logo from "../../../images/logo.jpg";
import "./Navigation.css";

const BottomNav = (props) => {
  const { data } = props;
  const loc = useLocation().pathname;

  // making url
  function convertToSlug(inputString) {
    const lowercaseString = inputString.toLowerCase();
    const hyphenatedString = lowercaseString.replace(/\s+/g, "-");
    return hyphenatedString;
  }

  const filterData = data.filter(
    (e) => e.category_name.toLowerCase() !== "home"
  );

  const sortedData = filterData.sort(
    (a, b) => a.display_order - b.display_order
  );
  const finalData = sortedData.filter((e) => e.active === true);

  const [hamBarClk, setHamBarClk] = useState(false);

  const hambarclick = () => {
    setHamBarClk(!hamBarClk);
  };

  const [scrollY, setScrollY] = useState(window.scrollY);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hamBarClk) {
      document.body.style.overflow = "hidden";
      if (scrollY > 570) {
        document.querySelector(".TopShifter").style.display = "none";
      }
    } else {
      document.body.style.overflow = "auto";
      if (scrollY > 570) {
        document.querySelector(".TopShifter").style.display = "block";
      }
    }
    // eslint-disable-next-line
  }, [hamBarClk]);

  const closeNavBar = () => {
    setHamBarClk(false);
  };

  const locSub = loc.split("/");

  return (
    <div className="h-[60px]  flex items-center justify-center sticky -top-[1px] z-[500000000] shadow-lg bg-[#041962]">
      <nav className="flex  h-full w-full items-center justify-between">
        <div className=" h-full flex justify-center items-center">
          <img
            src={logo}
            alt="logo"
            className="max-h-[60px] w-[170px] h-full"
            loading="lazy"
          />
        </div>
        <div className="navBar">
          <ul className={hamBarClk ? `nav_links_mini` : ` nav_links`}>
            <li className="flex items-center justify-center flex-col">
              <Link
                onClick={closeNavBar}
                to="/"
                className="font-poppins text-white py-1"
              >
                Home
              </Link>
              {loc === "/" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>

            {finalData.map((e) => {
              return (
                <li className="relative group" key={e.id}>
                  <Link
                    onClick={
                      (e.category_key.length <= 0 ||
                        e.category_name.toLowerCase() === "") &&
                      closeNavBar
                    }
                    className="flex items-center gap-1 font-poppins text-white py-1"
                    to={
                      e.category_key.length > 0 &&
                      e.category_name.toLowerCase() !== ""
                        ? undefined
                        : convertToSlug(e.category_name)
                    }
                  >
                    {e.category_name}
                    {e.category_key.length > 0 &&
                      e.category_name.toLowerCase() !== "" && (
                        <IoIosArrowDown className="text-sm" />
                      )}
                  </Link>
                  {e.category_key.length > 0 &&
                    e.category_name.toLowerCase() !== "" && (
                      <ul className="subcat absolute z-50 hidden shadow-md group-hover:block min-w-[160px]">
                        <li className="flex flex-col bg-[#151f42] shadow-md font-poppins text-sm w-full">
                          {e.category_key.map((elm) => (
                            <Link
                              onClick={closeNavBar}
                              to={`${convertToSlug(
                                elm.category_name
                              )}/${convertToSlug(elm.category_key_name)}`}
                              className={`p-2 py-3 border-b border-slate-500 hover:bg-[#041962] ${
                                locSub[2] ===
                                  convertToSlug(elm.category_key_name) &&
                                "bg-[#041962] text-white"
                              } hover:text-white`}
                              key={elm.id}
                            >
                              {elm.category_key_name}
                            </Link>
                          ))}
                        </li>
                      </ul>
                    )}
                  {loc.includes(convertToSlug(e.category_name)) && (
                    <div className="w-full h-[3px] bg-slate-300 rounded" />
                  )}
                </li>
              );
            })}

            {/* <li>
              <Link
                onClick={closeNavBar}
                to="/academics"
                className="flex items-center gap-1 font-poppins text-white py-1"
              >
                Academics
              </Link>
              {loc === "/academics" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            <li>
              <Link
                onClick={closeNavBar}
                to="/journal"
                className="flex items-center gap-1 font-poppins text-white py-1"
              >
                Journal
                <IoIosArrowDown className="text-sm" />
              </Link>
              {loc === "/journal" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            <li>
              <Link
                onClick={closeNavBar}
                to="/gallery"
                className="flex items-center gap-1 font-poppins text-white py-1"
              >
                Gallery
              </Link>
              {loc === "/gallery" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            <li>
              <Link
                onClick={closeNavBar}
                to="/notes"
                className="flex items-center gap-1 font-poppins text-white py-1"
              >
                Notes
                <IoIosArrowDown className="text-sm" />
              </Link>
              {loc === "/notes" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            <li>
              <Link
                onClick={closeNavBar}
                to="/admission"
                className="font-poppins text-white py-1"
              >
                Admission
              </Link>
              {loc === "/admission" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li>
            <li>
              <Link
                onClick={closeNavBar}
                to="/contact"
                className="font-poppins text-white py-1"
              >
                Contact
              </Link>
              {loc === "/contact" && (
                <div className="w-full h-[3px] bg-slate-300 rounded" />
              )}
            </li> */}
          </ul>
        </div>
        <div className="hamBurger" onClick={hambarclick}>
          <div
            className={hamBarClk ? `lines c-White crossBar1 ` : `lines c-White`}
          ></div>
          <div
            className={hamBarClk ? `lines c-White crossBar2` : `lines c-White`}
          ></div>
          <div
            className={hamBarClk ? `lines c-White crossBar3` : `lines c-White`}
          ></div>
        </div>
      </nav>
    </div>
  );
};

export default BottomNav;
