import React from "react";

const EachPostSlug = (props) => {
  const { data } = props;
  return (
    <>
      <div className="w-full h-full md:py-10 py-5 ">
        <div className="description-container min-h-[10vh] px-2 pb-3 w-[95%] md:gap-2 shadow md:!p-9 rounded m-auto lg:w-[70%] md:w-[85%]">
          {data.image && (
            <img
              src={data?.image}
              alt="this is name"
              className="p-2 !ml-3 !rounded img-responsive !w-full sm:!w-auto !max-w-[600px] sm:!max-w-[350px] !object-cover floating-image right !m-auto"
              loading="lazy"
            />
          )}
          <h3 className="md:text-3xl text-xl mb-1 font-bold">
            {data?.blog_title}
          </h3>
          <h3 className="md:text-xl text-lg font-semibold">
            {data?.author_name}
          </h3>
          <div
            className="text-sm md:text-base text-justify"
            dangerouslySetInnerHTML={{ __html: data?.blog_post }}
          />
        </div>
      </div>
    </>
  );
};

export default EachPostSlug;
