import { Button, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import TeacherCOntext from "../../../context/Teacher/TeacherCOntext";
// import PublicApiContext from "../../../context/PublicAPI/PublicApiContext";

const TeachersModal = (props) => {
  // const { subSubCategoryPublicApi, subSubCategorydata } =
  //   useContext(PublicApiContext);
  // useEffect(() => {
  //   subSubCategoryPublicApi();
  //   // eslint-disable-next-line
  // }, []);
  const { AddBtnCk, setAddBtnClk, editableData, btnData, uploaded } = props;
  const { postTeacher, UpdateTeacher } = useContext(TeacherCOntext);
  // for show or set data
  const [data, setData] = useState({
    name: btnData.name === "updateBtn" ? editableData[0].name : "",
    mobile_no: btnData.name === "updateBtn" ? editableData[0].mobile_no : "",
    address: btnData.name === "updateBtn" ? editableData[0].address : "",
    email: btnData.name === "updateBtn" ? editableData[0].email : "",
    designation:
      btnData.name === "updateBtn" ? editableData[0].designation : "",
    qualification:
      btnData.name === "updateBtn" ? editableData[0].qualification : "",
    image: btnData.name === "updateBtn" ? editableData[0].image : "",
    teams: btnData.name === "updateBtn" ? editableData[0].teams : "",
  });
  const [image, setImage] = useState("");
  const [num, setNum] = useState(
    btnData.name === "updateBtn" ? editableData[0].mobile_no : ""
  );
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const teacherHierData = [
    {
      name: "Board of Directors (BOD)",
      id: "1",
    },
    {
      name: "Executive Committee",
      id: "2",
    },
    {
      name: "Chairperson",
      id: "3",
    },
    {
      name: "Principal",
      id: "4",
    },
    {
      name: "Administration",
      id: "5",
    },
    {
      name: "Consulling and Well Being",
      id: "6",
    },
    {
      name: "Research",
      id: "7",
    },
    {
      name: "Library",
      id: "8",
    },
  ];

  const teacherDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  if (uploaded === 100) {
    setTimeout(() => {
      ref.current?.click();
      setAddBtnClk(false);
    }, 1000);
  }

  // for Modal show or hide
  const ref = useRef();
  useEffect(() => {
    if (AddBtnCk) {
      ref.current.click();
    } // eslint-disable-next-line
  }, []);
  const teacherImageChange = (e) => {
    setImage(e.target.files[0]);
    let a = e.target.files[0].name.split(".").pop().toLowerCase();
    if (a !== "png" && a !== "jpg" && a !== "jpeg") {
      setimgErrorMsg({
        status: true,
        msg: "Image extension can only be in png and jpg",
        position: "img",
      });
    } else {
      setimgErrorMsg({ status: false, msg: "", position: "" });
    }
  };

  // const teacherHierData = subSubCategorydata.filter(
  //   (e) => e.sub_category_name.toLowerCase() === "citizen team"
  // );

  const getTeamsName = (id) => {
    const result = teacherHierData.filter((e) => Number(e.id) === Number(id));
    if (result.length > 0) {
      return result[0].name;
    }
    return null;
  };

  const [teacherHierarchy, setTeacherHierarchy] = useState(
    !editableData[0]
      ? {
          name: "",
          id: 0,
        }
      : { name: getTeamsName(editableData[0].teams), id: editableData[0].teams }
  );
  const dropdownClk = (name, id) => {
    setTeacherHierarchy({ name, id });
  };

  // add btn click
  const AddBtnClk = (e) => {
    e.preventDefault();
    if (teacherHierarchy.id <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please Choose Teacher Designation",
        position: "designation",
      });
    } else if (data.name <= 0) {
      setErrorMsg({ status: true, msg: "Please Enter Name", position: "name" });
    } else if (num.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Valid Number",
        position: "mobile_no",
      });
    } else if (num.length > 15) {
      setErrorMsg({
        status: true,
        msg: "Number should be less than 14",
        position: "mobile_no",
      });
    } else if (num.length !== 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be 10 character",
        position: "mobile_no",
      });
    } else if (num.substring(0, 2) !== "97" && num.substring(0, 2) !== "98") {
      setErrorMsg({
        status: true,
        msg: "Mobile Number Must Start With 97 or 98",
        position: "mobile_no",
      });
    } else if (
      (image === null || typeof image !== "object") &&
      editableData[0] === undefined
    ) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Image",
        position: "image",
      });
    } else {
      if (imgErrorMsg.status === false) {
        setErrorMsg({ status: false, msg: "", position: "" });
        const finalData = {
          ...data,
          mobile_no: num,
          teams: teacherHierarchy.id,
        };
        if (btnData.name === "addBtn") {
          postTeacher(finalData, image);
        } else {
          UpdateTeacher(data, image, btnData.id);
        }
        // ref.current.click();
        // setAddBtnClk(false);
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn d-none btn-primary text-black"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]" id="teachers">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[30px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Create Teacher
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-2 text-black">
              <div
                className={`dropdown dropdownbtn !mt-0 ${
                  errorMsg.position === "designation" ? "!mb-3" : "!mb-0"
                } videosDropdown videosDropdownmanual`}
              >
                <div
                  className="btn btn-secondary !mt-0 dropdownbtn relative videosMidalDropdown dropbtn21 !pt-[10px]"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {data.teams.length > 0
                    ? getTeamsName(data.teams)
                    : teacherHierarchy.name === ""
                    ? "--Select Designation--"
                    : teacherHierarchy.name}

                  <AiOutlineCaretDown className="absolute right-3 top-5" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {teacherHierData.map((e) => {
                    return (
                      <li
                        className="category"
                        value={e.id}
                        name="None"
                        key={e.id}
                        onClick={() => dropdownClk(e.name, e.id)}
                      >
                        {e.name}
                      </li>
                    );
                  })}
                </ul>
                {errorMsg.position === "designation" ? (
                  <p className="text-red-500 text-xs ml-5 -mt-5 w-[600px]">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
              </div>
              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto mt-3"
                value={data.name}
                onChange={teacherDataChange}
                name="name"
                label="Name*"
                variant="outlined"
              />
              {errorMsg.position === "name" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-1">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}

              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={num}
                onChange={handleChange}
                name="mobile_no"
                label="Mobile Number*"
                variant="outlined"
              />
              {errorMsg.position === "mobile_no" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-1">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}

              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.address}
                onChange={teacherDataChange}
                name="address"
                label="Address"
                variant="outlined"
              />
              {/* {errorMsg.position === "address" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}

              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.email}
                onChange={teacherDataChange}
                name="email"
                label="Email"
                variant="outlined"
              />
              {/* {errorMsg.position === "email" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}

              {/* <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.designation}
                onChange={teacherDataChange}
                name="designation"
                label="Designation*"
                variant="outlined"
              />
              {errorMsg.position === "designation" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}

              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.qualification}
                onChange={teacherDataChange}
                name="qualification"
                label="Qualification"
                variant="outlined"
              />
              {/* {errorMsg.position === "qualification" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}

              <input
                className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                name="image"
                onChange={teacherImageChange}
                id="formFileLg"
                type="file"
                style={{ fontSize: "16px", paddingTop: "10px" }}
              />
              {errorMsg.position === "image" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-1">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {imgErrorMsg.position === "img" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-1">
                  **{imgErrorMsg.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            {uploaded && uploaded > 0 && (
              <div className="w-[85%] m-auto">
                <div className="progress my-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploaded}%` }}
                  >
                    {`${uploaded}%`}
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer pr-[50px]">
              <Button
                type="submit"
                variant="contained"
                onClick={AddBtnClk}
                size="small"
                className="mb-2 mt-1 !capitalize"
              >
                {btnData.name === "updateBtn" ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TeachersModal;
