import React from "react";
import EachTeacherCard from "./EachTeacherCard";

const RightCard = (props) => {
  const { topData, selectedValue, teacherdata } = props;
  const showData = teacherdata.filter(
    (e) => Number(e.teams) === Number(selectedValue)
  );
  return (
    <>
      <div className="w-[100%] pb-10">
        <div>
          <div className="text-base md:text-lg text-justify">
            <div dangerouslySetInnerHTML={{ __html: topData?.blog_post }} />
          </div>
        </div>
        <div className="!mt-10">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4  place-items-center sm:place-content-start">
            {showData.map((e) => {
              return <EachTeacherCard data={e} key={e.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RightCard;
