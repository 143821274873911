import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect } from "react";
import { useContext } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const EachImage = (props) => {
  const { id } = props;
  const { subGallerydata, subGalleryPublicApi } = useContext(PublicApiContext);
  const subGalleryData = subGallerydata;

  useEffect(() => {
    subGalleryPublicApi();
    // eslint-disable-next-line
  }, []);
  const Finaldata = subGalleryData.filter((e) => e.image_gallery_id === id);
  return (
    <>
      <Swiper
        pagination={{
          type: "progressbar",
        }}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="myImageSwiper"
      >
        {Finaldata.length >= 1 ? (
          <>
            {Finaldata.map((e, index) => {
              return (
                <SwiperSlide key={e.id}>
                  <div className=" relative h-screen w-full flex justify-center items-center">
                    <img
                      src={e.sub_gallery_image}
                      className="h-full max-h-[80vh] max-w-[80%] !mt-14 m-auto w-full !object-contain opacity-80 select-none"
                      alt=""
                      loading="lazy"
                    />
                    <h3 className="absolute bottom-4 left-5 text-white tracking-widest">
                      {index + 1}/{Finaldata.length}
                    </h3>
                  </div>
                </SwiperSlide>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Swiper>
    </>
  );
};

export default EachImage;
