import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import EachTestimonialContent from "./EachTestimonialContent";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

export default function Testimonials() {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      e.sub_category.toLowerCase() === "slug-5"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData;

  return (
    finalData.length > 0 && (
      <>
        <div className="pb-14 flex-col min-h-[50vh] bg-[#E6E6E650] ">
          <div className="flex justify-center items-center pt-14">
            <div className="md:my-5 my-3 text-4xl font-semibold border-b text-center font-abhaya text-[#041962] tracking-wide">
              Testimonials
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {finalData.map((e) => {
              return (
                <SwiperSlide key={e.id}>
                  <EachTestimonialContent data={e} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </>
    )
  );
}
