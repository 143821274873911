import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper"; // Import modules from 'swiper', not 'swiper/modules'
import SwiperPage from "./SwiperPage";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

export default function HeroSection() {
  const { getbannerPublicApi, bannerdata } = useContext(PublicApiContext);
  useEffect(() => {
    getbannerPublicApi();
    // eslint-disable-next-line
  }, []);
  const finteredData = bannerdata.filter(
    (e) => e.name.toLowerCase() === "home"
  );
  const data = finteredData.sort((a, b) => b.id - a.id);

  return (
    <div className="relative h-[90vh] w-full">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination]} // Pass modules as an array
        className="mySwiper"
      >
        {data.map((e) => {
          return (
            <SwiperSlide key={e.id}>
              <SwiperPage data={e} />
            </SwiperSlide>
          );
        })}

        {/* <SwiperSlide>
        <SwiperPage />
      </SwiperSlide> */}
      </Swiper>
    </div>
  );
}
