import React, { useContext, useEffect, useState } from "react";
import LeftCard from "./LeftCard";
import RightCard from "./RightCard";
import AboutHero from "../common/AboutHero";
import ContentHead from "../common/ContentHead";
import PublicApiContext from "../../../../../context/PublicAPI/PublicApiContext";

const Team = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen-Teams";
  }, []);

  const {
    getblogPublicApi,
    blogdata,
    // subSubCategorydata,
    // subSubCategoryPublicApi,
    teacherPublicApi,
    teacherdata,
  } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // subSubCategoryPublicApi();
    teacherPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) => e.category_name.toLowerCase() === "about us"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData;

  const teams =
    finalData.length > 0 &&
    finalData.filter((e) =>
      e.sub_category.toLowerCase().includes("citizen team")
    );

  // const teacherHierData = subSubCategorydata.filter(
  //   (e) => e.sub_category_name.toLowerCase() === "citizen team"
  // );

  const teacherHierData = [
    {
      name: "Board of Directors (BOD)",
      id: "1",
    },
    {
      name: "Executive Committee",
      id: "2",
    },
    {
      name: "Chairperson",
      id: "3",
    },
    {
      name: "Principal",
      id: "4",
    },
    {
      name: "Administration",
      id: "5",
    },
    {
      name: "Consulling and Well Being",
      id: "6",
    },
    {
      name: "Research",
      id: "7",
    },
    {
      name: "Library",
      id: "8",
    },
  ];

  const [selectedValue, setSelectedValue] = useState(0);

  const newTeacherHierarchy = teacherHierData.filter((hierEntry) =>
    teacherdata.some(
      (streamEntry) => Number(streamEntry.teams) === Number(hierEntry.id)
    )
  );
  useEffect(() => {
    if (selectedValue === 0 && newTeacherHierarchy.length > 0) {
      setSelectedValue(newTeacherHierarchy[0]?.id);
    } // eslint-disable-next-line
  }, [teacherHierData, newTeacherHierarchy]);

  return (
    Number(selectedValue) > 0 && (
      <>
        <AboutHero />
        <ContentHead head={"Team’s"} />
        <div className="w-[95%] m-auto min-h-[300px] flex-col md:flex-row !mt-14 flex gap-10 justify-between">
          <LeftCard
            data={
              newTeacherHierarchy.length > 0
                ? newTeacherHierarchy
                : teacherHierData
            }
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          <RightCard
            topData={teams[teams.length - 1]}
            selectedValue={selectedValue}
            teacherdata={teacherdata}
          />
        </div>
      </>
    )
  );
};

export default Team;
